import $api from '@/site/http'

export default class {

    static async getAll() {
        return $api.get('masters')
            .then(response => response)
    }

    static async getMaster(data = {}) {
        return $api.post('masters', data)
            .then(response => response)
    }

}