<template>
<div class="spinner__wrap">
  <div class="spinner__loading">
    <svg class="spinner__ico" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none"></circle></svg>
  </div>
</div>
</template>

<script>
export default {
  name: "Spinner"
}
</script>
