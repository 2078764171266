import masterService from '@/app/service/MasterService';

export default {

    namespaced: true,

    state: {

        ready: false,
        master: {
            professions: [],
            services: [],
            about: {},
            gallery: '',
            schedule: {},
            settings: [],
            addresses: [],
            salons: [],
            record: [],
        },

        professions: [],
        udobstva: [],

        isProcessingRecordSave: false

    },

    getters: {
        ready: state => state.ready,
        master: state => state.master,
        professions: state => state.professions,
        udobstva: state => state.udobstva,
        schedule: state => state.master.schedule,
        settings: state => state.master.settings,
        about: state => state.master.about,
        ledger: state => state.master.ledger,
        clients_groups: state => state.master.clients_groups,
        isProcessingRecordSave: state => state.isProcessingRecordSave,
    },

    mutations: {

        toggleRecordViewed(state, uuid) {
            const recordIdx = state.master.record.findIndex(r => r.uuid === uuid);
            state.master.record[recordIdx]['viewed'] = true;
        },

        setIsProcessingRecordSave(state, bool) {
            state.isProcessingRecordSave = bool;
        },

        setReady(state, bool) {
            state.ready = bool;
        },

        setUdobstva(state, udobstva) {
            state.udobstva = udobstva;
        },

        setProfessions(state, professions) {
            state.professions = professions;
        },

        setMaster(state, master) {
            state.master = master;
        },

        setMasterProfessions(state, professions) {
            state.master.professions = professions;
        },

        setMasterRecord(state, record) {
            state.master.record = record;
        },

        setMasterSchedule(state, schedule) {
            state.master.schedule = schedule;
        },

        setMasterAbout(state, about) {
            state.master.about = about;
        },

        setMasterService(state, service) {
            let index = state.master.services.findIndex(item => item.id === service.id);
            if (index === -1) {
                state.master.services.push(service);
            }
            else {
                state.master.services[index] = service;
            }
        },

        deleteMasterService(state, id) {
            state.master.services = state.master.services.filter(item => item.id !== id);
        },

        addMasterImageToGallery(state, image) {
            state.master.gallery.push(image);
        },

        removeMasterImageToGallery(state, id) {
            state.master.gallery = state.master.gallery.filter(item => item.id !== id);
        },

        setMasterSettings(state, settings) {
            const idxSetttings = state.master.settings.findIndex(s => s.type === settings.type);
            if (idxSetttings !== -1) state.master.settings[idxSetttings].data = settings.data;
            else state.master.settings.push(settings)
        },

        setUserPhoto(state, photo) {
            state.master.photo = photo;
        },

        setUserIcon(state, icon) {
            state.master.icon = icon;
        },

        setClientsGroups(state, groups) {
            state.master.clients_groups = groups;
        },

        setAddressMaster(state, address) {
            state.master.addresses = state.master.addresses.filter(a => a.uuid !== address.uuid);
            state.master.addresses.push(address);
        },

        removeAddressMaster(state, uuid) {
            state.master.addresses = state.master.addresses.filter(a => a.uuid !== uuid);
        },

        removeMasterRecord(state, uuid) {
            state.master.record = state.master.record.filter(b => b.uuid !== uuid)
        },

        // Drag Drop
        updateRecordData(state, record) {
            const findRecord = state.master.record.find(b => b.uuid === record.uuid);
            findRecord.date = record.date;
            findRecord.time_from = record.time.from;
            findRecord.time_to = record.time.to;
        },

    },

    actions: {

        async saveAddressMaster({commit}, data) {
            try {
                const res = await masterService.saveAddressMaster(data);
                commit('setAddressMaster', res.data);
                return {uuid: res.data.uuid};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async removeAddressMaster({commit}, uuid) {
            try {
                await masterService.removeAddressMaster(uuid);
                commit('removeAddressMaster', uuid);
                return {type: 'success', title: 'Успех!', text: 'Адрес удалён'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },


        async addGroupClient({commit}, group) {
            try {
                const res = await masterService.addClientGroup(group);
                commit('setClientsGroups', res.data);
                return {type: 'success', title: 'Успех!', text: 'Группа добавлена'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: "Обратитесь в тех. поддержку"};
            }
        },

        async updateClientGroup({commit}, group) {
            try {
                const res = await masterService.updateClientGroup(group);
                commit('setClientsGroups', res.data);
                return {type: 'success', title: 'Успех!', text: 'Группа обновлена'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: "Обратитесь в тех. поддержку"};
            }
        },

        async removeClientGroup({commit}, groupId) {
            try {
                const res = await masterService.removeClientGroup(groupId);
                commit('setClientsGroups', res.data);
                return {type: 'success', title: 'Успех!', text: 'Группа удалена'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.message};
            }
        },

        async saveSettings({commit}, settings) {
            try {
                await masterService.saveSettings(settings)
                commit('setMasterSettings', JSON.parse(JSON.stringify(settings)));
                return {type: 'success', title: 'Успех!', text: 'Настройки обновлены!'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async saveRecord({commit, dispatch}, record) {

            commit('setIsProcessingRecordSave', true)

            try {

                const res = await masterService.saveRecord(record);
                const err = res?.err || res?.data?.err;
                if (err) throw new Error(err);

                commit('setIsProcessingRecordSave', false)

                dispatch('devUpdateRecords');

                return {
                    type: 'success',
                    title: 'Успех!',
                    text: 'Запись успешно оформлена!',
                    data: res.data
                };

            }
            catch (e) {

                let title = "Произошла ошибка!";
                let text = e.message;

                switch (e.message) {
                    case "time-is-busy":
                        title = "Это время уже занято!";
                        text = "Попробуйте выбрать другое время.";
                        break;

                    case "time-is-not-available":
                        title = "Это время недоступно для записи!";
                        text = "Попробуйте выбрать другое время.";
                        break;

                }
                commit('setIsProcessingRecordSave', false)
                return {type: 'error', title, text};
            }


        },


        async getRecordData({}, uuid) {

            try {
                const {data} = await masterService.getRecordData(uuid);
                return data;
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.message};
            }

        },


        async cancelRecord({commit, dispatch}, recordId) {
            try {
                commit('setIsProcessingRecordSave', true);

                await masterService.cancelRecord(recordId);

                commit('removeMasterRecord', recordId);

                commit('setIsProcessingRecordSave', false);

                dispatch('devUpdateRecords');

                return {type: 'success', title: 'Успех!', text: 'Запись отменена!'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        devUpdateRecords({rootGetters, dispatch}) {
            if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_RUN_MODE === '' ) {
                const selectedDate = rootGetters['calendar/selectedDate'];
                const year = selectedDate.getFullYear();
                const month = selectedDate.getMonth();
                dispatch('getMasterScheduleAndRecord', {year, month, loader: false});
            }
        },

        async toggleRecordViewed({commit}, uuid) {
            commit('toggleRecordViewed', uuid);
            try {
                await masterService.toggleRecordViewed(uuid);
            }
            catch (e) { }


        },

        async init({dispatch}) {
            await dispatch("getMasterData");
        },

        async saveMasterScheduleWeek({commit}, week) {
            try {
                const res = await masterService.saveMasterScheduleWeek(week)
                commit('setMasterSchedule', res.data);
                return {type: 'success', title: 'Отлично!', text: 'Ваши данные сохранены'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async saveMasterScheduleDayOff({commit}, dayOff) {
            try {
                const res = await masterService.saveMasterScheduleDayOff(dayOff)
                commit('setMasterSchedule', res.data);
            }
            catch (e) {
                throw new Error(e.message);
            }
        },

        async removeMasterScheduleDayOff({commit}, id) {
            try {
                const res = await masterService.removeMasterScheduleDayOff(id)
                commit('setMasterSchedule', res.data);
                return {type: 'success', title: 'Отлично!', text: 'Ваши данные сохранены'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async saveMasterScheduleHoliday({commit}, dayOff) {
            try {
                const res = await masterService.saveMasterScheduleHoliday(dayOff)
                commit('setMasterSchedule', res.data);
                return {type: 'success', title: 'Отлично!', text: 'Ваши данные сохранены'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async removeMasterScheduleHoliday({commit}, id) {
            try {
                const res = await masterService.removeMasterScheduleHoliday(id)
                commit('setMasterSchedule', res.data);
                return {type: 'success', title: 'Отлично!', text: 'Ваши данные сохранены'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async getMasterData({commit, getters}, update = false) {
            if (getters.ready && !update) return;
            try {
                const res = await masterService.getMasterData()
                commit('setProfessions', res.data.professions);
                commit('setUdobstva', res.data.udobstva);
                commit('setMaster', res.data.master);
                commit('setReady', true);
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async getMasterScheduleAndRecord({state, dispatch, commit /*, rootGetters */}, data = {}) {

            try {
                const loader = data?.loader && data?.loader !== false ? true : false;
                if (loader) commit('setIsProcessingRecordSave', true);
                const res = await masterService.getMasterScheduleAndRecord(data);
                const {record, schedule} = res.data.master;
                commit('setMasterRecord', record);
                commit('setMasterSchedule', schedule);
                commit('setIsProcessingRecordSave', false);
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.message};
            }
        },

        async saveMasterProfessions({commit}, professions) {
            try {
                commit('setMasterProfessions', professions);
                await masterService.saveMasterData({professions})
                return {type: 'success', title: 'Отлично!', text: 'Ваши данные сохранены'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async saveMasterService({commit}, service) {
            try {
                const res = await masterService.saveMasterData({service});
                commit('setMasterService', res.data.master.service);
                return {type: 'success', title: 'Отлично!', message: 'Ваши данные сохранены'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', message: e.response?.data?.message};
            }
        },

        async saveMasterAbout({commit}, about) {
            try {
                const res = await masterService.saveMasterData({about});
                commit('setMasterAbout', res.data.master.about);
                return {type: 'success', title: 'Отлично!', text: 'Ваши данные сохранены'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async changeMasterServicePublish({commit, getters}, id) {
            try {
                const value = !getters.master.services.find(item => item.id === id).isPublished
                const res = await masterService.changeMasterServiceField({id, field: 'isPublished', value});
                commit('setMasterService', res.data.master.service[0]);
                return {type: 'success', title: 'Отлично!', message: 'Ваши данные сохранены'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', message: e.response?.data?.message};
            }
        },

        async deleteMasterService({commit, getters}, id) {
            try {
                const value = !getters.master.services.find(item => item.id === id).isDeleted
                const res = await masterService.changeMasterServiceField({id, field: 'isDeleted', value});

                if (res.data.master.service[0].isDeleted) commit('deleteMasterService', id);

                return {type: 'info', title: 'Удалено!', message: 'Услуга удалена успешно!'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', message: e.response?.data?.message};
            }
        },

        async uploadMasterGalleryImage({commit}, file) {
            try {
                const res = await masterService.uploadMasterGalleryPhoto(file);
                commit('addMasterImageToGallery', res.data);
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.message};
            }
        },

        async removeMasterGalleryImage({commit, getters}, id) {
            try {
                await masterService.removeMasterGalleryImage(id);
                commit('removeMasterImageToGallery', id);
                return {type: 'info', title: 'Успешно!', text: 'Фото удалено'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async uploadUserDataPhoto({commit}, file) {
            try {
                const res = await masterService.uploadUserDataPhoto(file);
                if (!res?.data?.user?.photo) throw new Error();
                commit('setUserPhoto', res.data.user.photo);
                commit('setUserIcon', res.data.user.icon);

                return {type: 'success', title: 'Отлично!', text: 'Ваше фото сохранено!'};
            }
            catch (e) {
                return {type: 'error', title: 'Упс! Что-то пошло не так', text: e.response?.data?.message};
            }
        },

        async getMasterClientsList({}) {
            try {
                const res = await masterService.getMasterClientsList();
                return res.data;
            }
            catch (e) {
                throw new Error(e);
            }
        },

        // Drag Drop
        updateRecordData({commit}, record) {
            commit('updateRecordData', record);
        },


    }

}