import axios from 'axios';
import fpPromise from './fp'

import store from '@/app/store';

const baseURL = 'api/'
const $api = axios.create({
  withCredentials: true,
  baseURL,
  timeout: 60000
});

// выводим в консоль response.data или error
// if (process.env.NODE_ENV === 'development') {
//     $api.interceptors.response.use(response => {
//         console.log(response);
//         return response;
//     }, async error => {
//         console.log('error: ', error);
//         throw error;
//     });
// }


// set headers devId
let devId = null;


const addAuthToken = async config => {

  if (localStorage.getItem('token'))

    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

  if ([
    // 'users/registration',
    // 'users/login',
    // 'users/logout',
    'users/master/check-code',
    'users/refresh',
    'users/check',
  ]
    .includes(config.url)
  ) {
    if (!devId) {
      const fp = await fpPromise
      const result = await fp.get()
      devId = result.visitorId + '_app';
    }
    config.headers.devid = devId;
  }
  return config;
}


// set headers Authorization
$api.interceptors.request.use(addAuthToken);


let i = 0;
// refresh auth if access token has expired
$api.interceptors.response.use(
  config => {
    return config;
  },
  async error => {

    let originalRequest = error.config;

    if (originalRequest.url === "master/users/send-code") return;

    if (error.response.status === 401 && originalRequest && !originalRequest?._isRetry) {
      originalRequest._isRetry = true;
      localStorage.removeItem('token');
      if (process.env.NODE_ENV === 'development') console.log('http/index.js: remove token')
      try {
        const response = await $api.get('users/refresh');
        originalRequest = await addAuthToken(originalRequest);
        localStorage.setItem('token', response.data.accessToken);
        return $api.request(originalRequest);
      }
      catch (e) {
        // console.log('НЕ АВТОРИЗОВАН');
        store.commit('user/setAuth', false);
      }
    }

    // if (error.response.status === 403) {
    //     throw error;
    // }

    throw {
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data.message,
      errors: error.response.data.errors,
    };

  });

export default $api;