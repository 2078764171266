import {createApp} from "vue";
import App from "@/App.vue";

import router from "@/router/";
import appMixin from "@/app/mixins/mixins";

import InputTelMasked from "@/components/InputTelMasked";
import appStore from "@/app/store";
import siteStore from "@/site/store";
import AppSpinner from "@/components/Spinner";

import mitt from 'mitt'

import ElementPlus from 'element-plus'
import ru from 'element-plus/lib/locale/lang/ru'


window.$ = window.jQuery = window.jquery = require("jquery");

const app = createApp(App);

app.config.globalProperties.emitter = mitt()

app.use(router);

const store = () => (window.location.pathname.match("/app") ? appStore : siteStore);

app.use(store());
app.mixin(appMixin);
app.component('AppInputTel', InputTelMasked)

app.use(ElementPlus, {locale: ru});
app.component('AppSpinner', AppSpinner);

app.mount("#app");
