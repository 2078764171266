import CustomersService from "@/site/service/CustomersService";

let autoLoginResolver;
let autoLoginPromise = new Promise(resolve => {
    autoLoginResolver = resolve
});

export default {

    namespaced: true,

    state: {
        isAuth: false,
        user: {
            name: "",
            phone: "",
            uuid: "",
        },
        currentStep: {},

        reviews: {
            count: 0,
            rate: 0,
            items: [],
        },

    },

    getters: {
        userData: state => state.user,
        isAuth: state => state.isAuth,
        currentStep: state => state.currentStep,
        reviews: state => state.reviews,
        ready: () => autoLoginPromise,
    },

    mutations: {

        setReviews(state, reviews) {
            state.reviews.items = reviews;
            state.reviews.count = reviews.length;
            const sum = reviews.reduce((sum, a) => sum += +a.rate, 0);
            state.reviews.rate = (Math.ceil(sum / reviews.length * 10) / 10);
        },

        setIsAuth(state, bool) {
            state.isAuth = bool;
        },

        setUser(state, user) {
            state.user = user;
        },

        setCurrentStep(state, step) {
            state.currentStep = step;
        },

    },

    actions: {
        async checkSmsCode({commit}, userData) {
            try {
                const res = await CustomersService.checkSmsCode(userData);
                const {user, accessToken} = res.data
                commit('setUser', user);
                localStorage.setItem('tokenCustomer', accessToken)
                commit('setIsAuth', true);
                return res;
            } catch (e) {
                throw new Error(e.message)
            }
        },

        async sendSmsCode({commit}, userData) {
            try {
                await CustomersService.sendSmsCode(userData);
            } catch (e) {
                return e;
            }
            return true;
        },

        async sendRecord({rootGetters, dispatch}, user) {
            try {
                const res = await CustomersService.sendRecord({
                    record: rootGetters['record/record'],
                    user,
                })

                if (res?.data?.refresh) { // если пользователь менял имя, то обновляем токен
                    localStorage.removeItem('tokenCustomer');
                    await dispatch('checkAuth');
                }

                return res.data;
            } catch (e) {

                return {err: 'error'}

            }
        },


        async sendReview({dispatch}, review) {
            try {
                const res = await CustomersService.sendReview(review);
                if (res?.data?.refresh) { // если пользователь менял имя, то обновляем токен
                    localStorage.removeItem('tokenCustomer');
                    await dispatch('checkAuth');
                }
                return res.data;
            } catch (e) {

                throw new Error(e.message);

            }
        },



        async checkOwnerRecord({dispatch}, recordUuid) {
            const { data } = await CustomersService.checkOwnerRecord(recordUuid);
            return data;
        },



        async getReviewByRecordUuid({}, recordUuid) {
            try {
                const res = await CustomersService.getReviewByRecordUuid(recordUuid);
                return res.data;
            } catch (e) {
                throw new Error(e.message);
            }
        },

        async getReviewsByMasterUuid({rootGetters, commit}) {

            try {
                const res = await CustomersService.getReviewsByMasterUuid(rootGetters['master/master'].uuid);
                commit('setReviews', res.data.reviews);
            } catch (e) {
                throw new Error(e.message);
            }
        },

        async checkAuth({commit}) {
            try {
                const res = await CustomersService.checkAuth();
                const {name, phone, uuid} = res.data.user;

                commit('setIsAuth', true);

                commit('setUser', {
                    name,
                    phone,
                    uuid,
                });
            } catch (e) {
                commit('setIsAuth', false)
            }

            autoLoginResolver();
        },


        async logout({}) {
            try {
                await CustomersService.logout();
                localStorage.removeItem('tokenCustomer');
                location.reload();
            } catch (e) {
                console.log('res e', e)
            }
        },

        setCurrentStep({commit}, step) {
            commit('setCurrentStep', step);
        },

    }

}