export default class {
    init = {

        namespaced: true,

        state: {

            record: {
                masterId: '',
                serviceIds: [],
                date: 0,
                time: {
                    from: 0,
                    to: 0,
                },
            },

            master: null,

            calendar: {}, // какой год и месяц выбраны в календаре, для получения броней мастера
            serviceTimes: [], // список доступного времени для записи


            mode: '',

            recordOpenToEdit: null,
            timeForRecord: 0,


            draggingRecord: null,


        },


        getters: {
            record: state => state.record,
            master: state => state.master,
            mode: state => state.mode,
            calendar: state => state.calendar,
            serviceTimes: state => state.serviceTimes,
            recordOpenToEdit: state => state.recordOpenToEdit,
            timeForRecord: state => state.timeForRecord,
            draggingRecord: state => state.draggingRecord,

        },

        mutations: {

            setDraggingRecord(state, record){
                state.draggingRecord = record;
            },

            setRecordMode(state, mode) {
                state.mode = mode
            },

            setMaster(state, master) {
                state.master = master
            },

            setRecordMasterId(state, id) {
                state.record.masterId = id
            },

            setCalendar(state, calendar) {
                state.calendar = calendar
            },

            setServiceTimes(state, serviceTimes) {
                state.serviceTimes = serviceTimes;
            },

            setRecordTime(state, time) {
                state.record.time.from = +time.from;
                state.record.time.to = +time.to;
            },

            setRecordDate(state, date) {
                state.record.date = +date;
                state.record.time = { from: 0, to: 0 };
            },

            setRecordService(state, serviceId) {
                serviceId = +serviceId;
                if (state.record.serviceIds.includes(serviceId)) {
                    state.record.serviceIds = state.record.serviceIds.filter(item => item !== serviceId);
                }
                else {
                    state.record.serviceIds.push(serviceId);
                }
            },

            clearServiceIds(state) {
                state.record.serviceIds = [];
            },

            setRecordToEdit(state, uuid) {
                state.recordOpenToEdit = uuid;
            },

            setTimeForRecord(state, time) {
                state.timeForRecord = time;
            },

        },

        actions: {

            setDraggingRecord({ commit }, record) {
                commit('setDraggingRecord', record);
            },

            setMaster({ commit }, master) {
                commit('setMaster', master);
            },

            setRecordMode({ commit }, [mode = '', uuid = '']) {
                commit('setRecordMode', mode);
            },

            setRecordModeCustomer({ commit }, [mode = '', uuid = '']) {
                commit('setRecordMode', mode);
                if (mode === 'new') {
                    commit('setRecordTime', { from: 0, to: 0 });
                    commit('setRecordDate', 0);
                    commit('clearServiceIds');
                }
            },

            setRecordTime({ commit, state }, { from, to }) {
                commit('setRecordTime', { from, to });
            },


            calendarSelectDay({ commit }, data) {
                const { date, serviceTimes } = data;
                commit('setRecordDate', date);
                commit('setServiceTimes', serviceTimes);

            },

            setRecordService({ commit }, serviceId) {
                commit('setRecordService', serviceId);
            },

            setRecordServiceCustomer({ commit }, serviceId) {
                commit('setRecordService', serviceId);
                commit('setServiceTimes', []);
                commit('setRecordDate', 0);
                commit('setRecordTime', { from: 0, to: 0 });
            },

            clearServiceIds({ commit }) {
                commit('clearServiceIds', []);
                commit('setServiceTimes', []);
                commit('setRecordDate', 0);
                commit('setRecordTime', { from: 0, to: 0 });
            },

            setServiceTimes({ commit }, data) {
                commit('setServiceTimes', data);
            },

            setCalendar({ commit }, data) {
                commit('setCalendar', data);
            },

            setRecordMasterId({ commit }, masterId) {
                commit('setRecordMasterId', masterId);
            },

            setRecordDate({ commit }, date) {
                commit('setRecordDate', date);
            },

            setRecordData({ commit }, record) {
                if (typeof record === 'string') record = JSON.parse(JSON.stringify(record));

                const {
                    date,
                    services,
                    time_from: from,
                    time_to: to,
                } = record;

                commit('clearServiceIds');

                if( services?.length ) services.forEach(s => commit('setRecordService', s.id))

                commit('setServiceTimes', []);
                commit('setRecordDate', date);
                commit('setRecordTime', { from, to });
            },

            setRecordToEdit({ commit }, uuid) {
                commit('setRecordToEdit', uuid);
            },

            // при редактировании или создании записи (кликом по времени в календаре в админке)
            // сохраняем время, чтобы автоматически подставлять выбранные дату и время в календаре
            // (если это время доступно в принципе)
            setTimeForRecord({ commit }, time) {
                commit('setTimeForRecord', time);
            },

        }


    }
}