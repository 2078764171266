import $api from '../http/index'

export default class {

    static async saveUserData(data) {
        return $api.post('user', data)
            .then(response => response)
    }


}
