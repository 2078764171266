import siteRouter from "@/router/site";
import masterRouter from "@/router/master";

console.log({
    hostname: window.location.hostname,
    VUE_APP_AXIOS_HOSTNAME: process.env.VUE_APP_AXIOS_HOSTNAME,
})

const router = () => {
    return window.location.hostname === process.env.VUE_APP_AXIOS_HOSTNAME ? siteRouter : masterRouter
}

export default router()