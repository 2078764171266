import fpPromise from '@/app/http/fp'
import axios from 'axios';


const baseURL = 'open-api/';

const $api = axios.create({
    withCredentials: true,
    baseURL,
    timeout: 10000
});

// выводим в консоль response.data или error
if (process.env.NODE_ENV === 'development') {
    $api.interceptors.response.use(response => {
        console.log(response);
        return response;
    }, async error => {
        throw error;
    });
}



// set headers devId
let devId = null;
$api.interceptors.request.use(addAuthToken);

async function addAuthToken (config){

    // set headers Authorization

    if( localStorage.getItem('tokenCustomer') )
        config.headers.Authorization = `Bearer ${localStorage.getItem('tokenCustomer')}`;

    if ([
        'customer/record',
        'customer/check',
        'customer/refresh',
        'customer/logout',
        'customer/check-sms-code',
    ].includes(config.url)
    ) {
        if (!devId) {
            const fp = await fpPromise
            const result = await fp.get()
            devId = result.visitorId + '_site';
        }
        config.headers.devid = devId;
    }
    return config;
}

// refresh auth if access token has expired
$api.interceptors.response.use(config => {
    return config;
}, async error => {

    let originalRequest = error.config;

    if (error.response.status == 401 && originalRequest && !originalRequest._isRetry) {
        originalRequest._isRetry = true;
        localStorage.removeItem('tokenCustomer');
        try {
            const response = await $api.get('customer/refresh');
            localStorage.setItem('tokenCustomer', response.data.accessToken);
            originalRequest = await addAuthToken(originalRequest);
            return $api.request(originalRequest);
        }
        catch (e) {
            // console.log('НЕ АВТОРИЗОВАН');
        }
    }
    // throw error;

    throw {
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
        errors: error.response.data.errors,
    };

});




export default $api;
