import {createRouter, createWebHistory} from 'vue-router'  // createWebHistory || createWebHashHistory
import store from '@/site/store';


const routes = [

    {
        path: '/',
        component: () => import('@/site/views/Index'),
        children: [
            {
                name: 'index',
                path: '',
                component: () => import('@/site/views/MainPage'),
            },
            {
                name: 'masters',
                path: 'master',
                component: () => import('@/site/views/Masters'),
            },

        ],
    },

    {
        name: 'master-personal-page',
        path: '/master/:login',
        component: () => import('@/site/views/Master'),
        children: [
            {
                name: 'master-about',
                path: '',
                component: () => import('@/site/components/Master/About'),
                meta: {
                    title: ''
                }
            },
            {
                name: 'master-map',
                path: 'map',
                component: () => import('@/site/components/Master/Map'),
                meta: {
                    title: 'Мастер на карте'
                }
            },
            {
                name: 'master-portfolio-page',
                path: 'portfolio',
                component: () => import('@/site/components/Master/PortfolioPage'),
                meta: {
                    title: 'Портфолио'
                }
            },
            {
                name: 'master-widget',
                path: 'widget',
                component: () => import('@/site/components/Master/Widget'),
                meta: {
                    title: 'Онлайн запись'
                }
            },
            {
                name: 'master-record-success',
                path: 'record-success',
                component: () => import('@/site/components/MasterRecord/RecordSuccess'),
                meta: {
                    title: 'Вы записаны!'
                }
            },
            {
                name: 'master-reviews',
                path: 'reviews',
                component: () => import('@/site/components/Reviews'),
                meta: {
                    title: 'Отзывы'
                },
            },
            {
                name: 'master-review-page',
                path: 'review/add/:recordUuid',
                component: () => import('@/site/components/Reviews/AddReview'),
                meta: {
                    title: 'Новый отзыв'
                },
            },
        ]
    },


    {
        name: 'customer',
        path: '/customer',
        component: () => import('@/customer/views/Customer'),
        children: [
            {
                name: 'customer-record',
                path: '',
                component: () => import('@/customer/views/Record'),
            },
            {
                name: 'customer-record-page',
                path: 'record/:uuid',
                component: () => import('@/customer/views/RecordPage'),
            },

        ]
    },

    {
        name: 'app',
        path: '/app',
        meta: {auth: true},
        component: () => import('@/app/views/Index'),
        children: [

            {
                name: 'app-master',
                path: '',
                component: () => import('@/app/views/Master'),
                children: [

                    {
                        name: 'schedule',
                        path: '',
                        component: () => import('@/app/views/Schedule'),
                    },


                    {
                        name: 'record',
                        path: 'record/:uuid',
                        component: () => import('@/app/views/RecordPage'),
                    },

                    {
                        name: 'record-editor',
                        path: 'record-editor/:uuid',
                        component: () => import('@/app/views/RecordPageEditor'),
                    },

                    {
                        name: 'ledger',
                        path: 'ledger',
                        component: () => import('@/app/views/Ledger'),
                    },

                    {
                        name: 'ledger-monthly',
                        path: 'ledger-monthly',
                        component: () => import('@/app/views/LedgerMonthly'),
                    },


                    {
                        name: 'client',
                        path: 'client/:uuid',
                        component: () => import('@/app/views/Client'),
                    },

                    {
                        name: 'clients',
                        path: 'clients',
                        component: () => import('@/app/views/Clients'),
                    },

                    {
                        name: 'clients-groups',
                        path: 'clients-groups',
                        component: () => import('@/app/views/ClientsGroups'),
                    },


                    {
                        name: 'profile',
                        path: 'profile',
                        component: () => import('@/app/views/Profile/Profile'),
                        children: [
                            {
                                name: 'profile-data',
                                path: '',
                                component: () => import('@/app/views/Profile/MyData'),
                            },
                            {
                                name: 'profile-about',
                                path: 'about',
                                component: () => import('@/app/views/Profile/About'),
                            },
                            {
                                name: 'profile-domains',
                                path: 'domains',
                                component: () => import('@/app/views/Profile/Domains'),
                            },

                            {
                                name: 'profile-portfolio',
                                path: 'portfolio',
                                component: () => import('@/app/views/Profile/Portfolio'),
                            },
                            {
                                name: 'profile-pwa',
                                path: 'pwa',
                                component: () => import('@/app/views/Profile/PWA'),
                            },
                        ]
                    },

                    {
                        name: 'profile-addresses',
                        path: 'address',
                        component: () => import('@/app/views/Addresses'),
                    },

                    {
                        name: 'profile-address-page',
                        path: 'address/:uuid',
                        component: () => import('@/app/views/AddressPage'),
                    },

                    {
                        name: 'settings',
                        path: 'settings',
                        component: () => import('@/app/views/Settings'),
                    },

                    {
                        name: 'calendar-settings',
                        path: 'calendar-settings',
                        component: () => import('@/app/views/CalendarSettings'),
                    },

                    {
                        name: 'profession',
                        path: 'profession',
                        component: () => import('@/app/views/Profession'),
                    },

                    {
                        name: 'services',
                        path: 'services',
                        component: () => import('@/app/views/Services'),
                    },

                    {
                        name: 'work-schedule',
                        path: 'work-schedule',
                        component: () => import('@/app/views/WorkSchedule'),
                    },

                    {
                        name: 'sms-sendings',
                        path: 'sms-sendings',
                        component: () => import('@/app/views/SMS'),
                    },

                    {
                        name: 'sms-sendings-new',
                        path: 'sms-sendings-new',
                        component: () => import('@/app/views/SendingAdd'),
                    },

                    {
                        name: 'sms-sendings-page',
                        path: 'sms-sendings-page/:uuid',
                        component: () => import('@/app/views/SendingPage'),
                    },

                    {
                        name: 'sms-templates',
                        path: 'sms-templates',
                        component: () => import('@/app/views/SMSTemplates'),
                    },

                ]
            },

        ]
    },

    {path: '/api'},
    {path: '/open-api'},


    {
        name: 'E404',
        path: '/:pathMatch(.*)*',
        component: () => import('@/site/views/E404')
    },


];


const router = createRouter({
    history: createWebHistory(), // createWebHistory || createWebHashHistory
    routes
});


router.beforeEach((to, from, next) => {
    store.commit('route/SET_ROUTE', to);
    next();
});


router.afterEach((to, from) => {
    window.scrollTo(0, 0);
})


//


export default router;