import { createStore } from 'vuex';

import user from './user';
import master from './master';
import calendar from './calendar';

import recordStore from '@/site/store/record';
const record = new recordStore();

export default createStore({
    modules: {
        user,
        master,
        record: record.init,
        calendar,
    },
    strict: process.env.NODE_ENV !== 'production'
})
