const currentMonth = new Date();
currentMonth.setDate(1);
currentMonth.setHours(0);
currentMonth.setMinutes(0);
currentMonth.setSeconds(0);
currentMonth.setMilliseconds(0);

const currentDate = new Date();
currentDate.setHours(0);
currentDate.setMinutes(0);
currentDate.setSeconds(0);
currentDate.setMilliseconds(0);


export default {

    namespaced: true,

    state: {

        currentMonth,
        mode: 'week',
        selectedDate: currentDate, // выбранная дата
        selectedWeek: '', // номер выбранной недели
        weeks: [], // все недели для текущего состояния календаря
        selectedWeekDays: [], // даты выбранной недели

        step: 15,
        hours: new Set(),


        // в компоненте Schedule -> Calendar -> Week по интервалу обновляется текущее время,
        // для динамичной отметки времени в календаре как прошедшее (для текущего дня)
        updatingTimeIsOut: Math.ceil( Date.now() / 1000 ),



    },

    getters: {
        selectedDate: state => state.selectedDate,
        selectedWeek: state => state.selectedWeek,
        selectedWeekDays: state => state.selectedWeekDays,
        currentMonth: state => state.currentMonth,
        mode: state => state.mode,

        weeks: state => state.weeks,

        step: state => state.step,

        minutes: state => {
            const minutes = [];
            for (let i = 0; i < 60; i += state.step) minutes.push(i);
            return minutes;
        },

        hours: state => (Array.from(state.hours || [])).sort((a, b) => a - b),


        updatingTimeIsOut: state => state.updatingTimeIsOut,

    },

    mutations: {

        setHour(state, hour) {
            state.hours.add(hour);
        },

        resetHours(state) {
            state.hours = new Set();
        },

        setSelectedWeekDays(state, weeks) {
            state.selectedWeekDays = weeks;
        },

        setWeeks(state, weeks) {
            state.weeks = weeks;
        },

        setWeeksItem(state, week) {
            state.weeks.push(week);
        },



        setSelectedDate(state, date) {
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            state.selectedDate = date;
        },

        setPrevMonth(state) {
            state.currentMonth = new Date(state.currentMonth.setMonth(state.currentMonth.getMonth() - 1));
        },

        setSelectedWeek(state, week) {
            state.selectedWeek = week;
        },

        setNextMonth(state) {
            state.currentMonth = new Date(state.currentMonth.setMonth(state.currentMonth.getMonth() + 1));
        },

        setCurrentMonth(state, date) {
            date.setDate(1);
            state.currentMonth = date;
        },

        setMode(state, mode) {
            state.mode = mode;
        },

        updaterTimeIsOut(state) {
            state.updatingTimeIsOut = Math.ceil( Date.now() / 1000 );
        },

    },

    actions: {

        setHour({commit}, hour) {
            commit('setHour', hour);
        },

        setWeeks({state, commit, dispatch}, weeks) {
            weeks.forEach(week => {
                const findWeek = state.weeks.some(weekFind => weekFind.key === week.key);
                if (!findWeek) commit('setWeeksItem', week);
            });

            // при инициализации компонента
            if (!state.selectedWeekDays.length) {
                dispatch('setSelectedWeekDays');
            }

        },

        setSelectedWeek({commit}, week) {
            commit('setSelectedWeek', week)
        },

        setPrevMonth({commit}) {
            commit('setWeeks', [])
            commit('setPrevMonth')
        },

        setNextMonth({commit}) {
            commit('setWeeks', [])
            commit('setNextMonth')
        },

        setCurrentMonth({commit}, date) {
            commit('setWeeks', [])
            commit('setCurrentMonth', date);
        },

        setMode({commit}, mode) {
            commit('setMode', mode)
        },

        setSelectedDate({commit, dispatch}, {date, week}) {
            commit('setSelectedDate', date);
            commit('setSelectedWeek', week);
            dispatch('setSelectedWeekDays');
        },


        setSelectedWeekDays({state, commit, rootGetters}) {
            commit('resetHours');

            const {days} = state.weeks.find(week => week.key === state.selectedWeek);
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);

            const schedule = JSON.parse(JSON.stringify(rootGetters['master/master']['schedule']) );
            const settings = JSON.parse(JSON.stringify(rootGetters['master/master']['settings']) );

            const calendarSettings = settings.find(s => s.type === 'calendar-settings').data;

            const countDaysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

            const countMonthVisible = countDaysInMonth - today.getDate() <= calendarSettings.countDaysUntilEndMonth
                       ? calendarSettings.countVisibleMonthsInCalendar
                       : calendarSettings.countVisibleMonthsInCalendar - 1; // если кол дней до конца месяца меньше или равно calendarSettings.countDaysUntilEndMonth,
                                                                                 // то показать еще один месяц в календаре

            // последний доступный для записи месяц
            let lastMonthVis = (new Date(
                today.getFullYear(),
                today.getMonth() + countMonthVisible
                )
            ).getMonth();

            const weeks = days.map(d => {

                const currentYear = d.date.getFullYear();
                const currentMonth = d.date.getMonth();
                const currentDay = d.date.getDate();

                // в конце года могут быть накладки
                if( lastMonthVis === 0 || (currentMonth + countMonthVisible) > 11 ) {
                    lastMonthVis = 11;
                }

                const currentDate = new Date(currentYear, currentMonth, currentDay, 0, 0, 0, 0);
                const currentDateTs = currentDate.getTime() / 1000;

                let dayNum = currentDate.getDay();
                if (dayNum === 0) dayNum = 7;
                dayNum -= 1;

                const currentDayWeekInSchedule = schedule['week'].find(d => d.dayNum === dayNum);
                const isDayInHoliday = schedule['holiday'].some(holiday => (currentDateTs >= holiday.from && currentDateTs <= holiday.to));
                const dayOff = schedule['dayOff'].find(dayOff => (currentDateTs === dayOff.date));

                if( dayOff ) {
                    currentDayWeekInSchedule.time = dayOff.time
                    currentDayWeekInSchedule.intervals = dayOff.intervals
                }

                let workTime = {};

                if (!isDayInHoliday) {

                    const {time: {from, to}, intervals} = currentDayWeekInSchedule;

                    const intervalsTs = intervals.map(int => {
                        const {time: {from, to}} = int;
                        return {
                            from: new Date(currentYear, currentMonth, currentDay, from.h, from.m).getTime() / 1000,
                            to: new Date(currentYear, currentMonth, currentDay, to.h, to.m).getTime() / 1000,
                        };
                    });

                    workTime = {
                        y: currentYear,
                        m: currentMonth,
                        d: currentDay,
                        from: new Date(currentYear, currentMonth, currentDay, from.h, from.m).getTime() / 1000,
                        to: new Date(currentYear, currentMonth, currentDay, to.h, to.m).getTime() / 1000,
                        intervals: intervalsTs,
                    }

                }




                return {
                    date: d.date,
                    past: currentDateTs < today.getTime() / 1000,
                    holiday: currentDayWeekInSchedule.holiday || isDayInHoliday,
                    dayNum,
                    workTime,
                    available: currentMonth <= lastMonthVis,
                };

            });

            commit('setSelectedWeekDays', weeks);
        },


        updaterTimeIsOut({ commit }) {
            commit('updaterTimeIsOut');
        },


    }

}