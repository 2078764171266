import { createStore } from 'vuex';
import master from './master';
import customer from './customer';
import user from './user';

import recordStore from './record';
const record = new recordStore();

import route from './route';
export default createStore({
    modules: {
        master,
        route,
        customer,
        user,
        record: record.init,
    },
    strict: process.env.NODE_ENV !== 'production'
})
