import MasterService from "@/site/service/MasterService";

let autoLoginResolver;
let autoLoginPromise = new Promise(resolve => {
    autoLoginResolver = resolve
});

export default {

    namespaced: true,

    state: {
        master: null,
        professions: [],
        udobstva: [],

        isPwaInstalled: false,

        ready: () => autoLoginPromise,
    },

    getters: {
        master: state => state.master,
        user: state => state.user,
        professions: state => state.professions,
        udobstva: state => state.udobstva,
        ready: state => state.ready,

        isIos() {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        },

        isAndroid() {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /android/.test(userAgent);
        },

        isPwaInstalled: state => state.isPwaInstalled,

    },

    mutations: {

        setIsPwaInstalled(state, flag){
            state.isPwaInstalled = flag
        },

        setMaster(state, master) {
            state.master = master
        },
        setProfessions(state, professions) {
            state.professions = professions
        },
        setUdobstva(state, udobstva) {
            state.udobstva = udobstva;
        },
        setReady(state, ready) {
            state.ready = ready
        },
    },

    actions: {

        async getMasterData({ dispatch, commit, rootState }, d = {}) {
            const data = JSON.parse(JSON.stringify(d))

            data.domain = (window.location.hostname !== process.env.VUE_APP_AXIOS_HOSTNAME) ? window.location.hostname : '';
            data.login = rootState?.route?.route?.params?.login || '';

            try {
                const res = await MasterService.getMaster(data);
                if (res.data.master.redirect) {
                    console.log('window.location.replace')
                    window.location.replace('//' + res.data.master.domain);
                    return;
                }
                commit('setMaster', res.data.master);
                commit('setProfessions', res.data.professions);
                commit('setUdobstva', res.data.udobstva);

                dispatch('record/setRecordMasterId', res.data.master.uuid, { root: true });


            }
            catch (e) { }

            //commit('setReady', true);
            autoLoginResolver();

        },


        setMaster({commit}, data){
            commit('setMaster', data);
        },

        async getMasterScheduleAndRecord({ dispatch }, data = {}) {
            await dispatch('getMasterData', data)
        },


        setIsPwaInstalled({commit}, flag){
            commit('setIsPwaInstalled', flag);
        },

        share({state}) {
            if (navigator.share) {
                const title = `${state.master.surname} ${state.master.name}` + (state.master?.about?.text ? ` - ${state.master.about.title}` : '');

                navigator.share({
                    title,
                    text: title,
                    url: window.location.href
                })
                // .then(function () {
                //   console.log("Shareing successfull")
                // })
                // .catch(function () {
                //   console.log("Sharing failed")
                // })
            }
            else {
                console.log("Web Share APIs are not supported")
            }

        },


    }


}