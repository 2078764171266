import {createRouter, createWebHistory} from 'vue-router'  // createWebHistory || createWebHashHistory
import store from '@/site/store';



const routes = [


    {
        name: 'master',
        path: '/',
        component: () => import('@/site/views/Master'),
        children: [
            {
                name: 'master-about',
                path: '',
                component: () => import('@/site/components/Master/About'),
                meta: {
                    title: ''
                }
            },
            {
                name: 'master-map',
                path: '',
                component: () => import('@/site/components/Master/Map'),
                meta: {
                    title: 'Мастер на карте'
                }
            },
            {
                name: 'master-portfolio-page',
                path: 'portfolio',
                component: () => import('@/site/components/Master/PortfolioPage'),
                meta: {
                    title: 'Портфолио'
                }
            },
            {
                name: 'master-widget',
                path: 'widget',
                component: () => import('@/site/components/Master/Widget'),
                meta: {
                    title: 'Онлайн запись'
                }
            },
            {
                name: 'master-record-success',
                path: 'record-success',
                component: () => import('@/site/components/MasterRecord/RecordSuccess'),
                meta: {
                    title: 'Вы записаны!'
                }
            },
            {
                name: 'master-reviews',
                path: 'master-reviews',
                component: () => import('@/site/components/Master/Reviews'),
                meta: {
                    title: 'Отзывы'
                }
            },
        ]
    },

    {path: '/api'},
    {path: '/open-api'},

    {
        name: 'E404',
        path: '/:pathMatch(.*)*',
        component: () => import('@/site/views/E404')
    },

]

const router = createRouter({
    history: createWebHistory(), // createWebHistory || createWebHashHistory
    routes
});

router.beforeEach((to, from, next) => {
    store.commit('route/SET_ROUTE', to);
    next();
});


export default router;