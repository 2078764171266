import $api from '@/site/http'

export default class {

    static async sendSmsCode(user) {
        return $api.post('customer/send-sms-code', { user })
            .then(response => response)
    }

    static async checkSmsCode(user) {
        return $api.post('customer/check-sms-code', { user })
            .then(response => response)
    }


    static async checkAuth() {
        return $api.get('customer/check')
            .then(response => response)
    }


    static async logout() {
        return $api.post('customer/logout')
    }


    static async sendRecord(record) {
        return $api.post('customer/record', record)
            .then(response => response)
    }

    static async editRecord(uuid, record) {
        return $api.put(`customer/record/${uuid}`, record)
            .then(response => response)
    }

    static async getAllRecord() {
        return $api.get('customer/record')
            .then(response => response)
    }

    static async cancelRecord(uuid) {
        return $api.delete(`customer/record/${uuid}`)
            .then(response => response)
    }

    static async getRecord(uuid) {
        return $api.get(`customer/record/${uuid}`)
            .then(response => response)
    }

    static async sendReview(review) {
        return $api.post('customer/review', review)
            .then(response => response)
    }

    static async getReviewByRecordUuid(recordUuid) {
        return $api.get(`customer/review/${recordUuid}`)
            .then(response => response)
    }

    static async getReviewsByMasterUuid(masterUuid) {
        return $api.get(`customer/reviews/${masterUuid}`)
            .then(response => response)
    }

    static async checkOwnerRecord(recordUuid) {
        return $api.get(`customer/record-check-owner/${recordUuid}`)
            .then(response => response)
    }









}
