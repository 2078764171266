import $api from '../http/index'

export default class AuthService {

    static async checkAuth() {
        return $api.get('users/check')
            .then(response => response)
    }

    static async sendMasterSmsCode(user) {
        return $api.post('users/master/send-code', user)
            .then(response => response)
    }

    static async checkMasterSmsCode(user) {
        return $api.post('users/master/check-code', user)
            .then(response => response)
    }

    static async login(data) {
        return $api.post('users/login', data)
            .then(response => response)
    }

    static async logout() {
        return $api.post('users/logout')
    }


    //
    // static async registration(data) {
    //     return $api.post('users/registration', data)
    //         .then(response => response)
    // }
    //

    //
    // static async passwordReset(email) {
    //     return $api.post('users/passwordreset', { email })
    //         .then(response => response)
    // }







}