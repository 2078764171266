export default {

    namespaced: true,

    state: {
        route: null
    },

    mutations: {
        SET_ROUTE(state, route) {
            state.route = route;
        }
    },

}