import $api from '../http/'

export default class {


    static async getMasterData() {
        return $api.get('master')
            .then(response => response)
    }

    static async saveMasterData(data) {
        return $api.post('master', data)
            .then(response => response)
    }

    static async changeMasterServiceField(data) {
        return $api.put('master', data)
            .then(response => response)
    }

    static async uploadUserDataPhoto(files) {
        return $api.post('user/photo', files)
            .then(response => response)
    }

    static async uploadMasterGalleryPhoto(file) {
        return $api.post('master/file-gallery', file)
            .then(response => response)
    }

    static async uploadMasterPwaIcon(file) {
        return $api.post('master/pwa-icon', file)
            .then(response => response)
    }

    static async removeMasterPwaIcon() {
        return $api.delete(`master/pwa-icon`)
            .then(response => response)
    }

    static async removeMasterGalleryImage(id) {
        return $api.delete(`master/file-gallery`, { data: { id } })
            .then(response => response)
    }

    static async saveMasterScheduleWeek(week) {
        return $api.post('master/schedule/week', week)
            .then(response => response)
    }

    static async saveMasterScheduleDayOff(dayOff) {
        return $api.post('master/schedule/dayoff', dayOff)
            .then(response => response)
    }

    static async removeMasterScheduleDayOff(id) {
        return $api.delete(`master/schedule/dayoff/${id}`, )
            .then(response => response)
    }



    static async saveMasterScheduleHoliday(holiday) {
        return $api.post('master/schedule/holiday', holiday)
            .then(response => response)
    }

    static async removeMasterScheduleHoliday(id) {
        return $api.delete(`master/schedule/holiday/${id}`, )
            .then(response => response)
    }


    static async getMasterActiveRecord() {
        return $api.get('master/get-master-active-record')
            .then(response => response)
    }



    static async getMasterScheduleAndRecord(data = {}) {
        return $api.post('master/get-master-schedule-and-record', data)
            .then(response => response)
    }

    static async saveRecord(data) {
        return $api.post('master/record', data)
            .then(response => response)
    }

    static async cancelRecord(recordId) {
        return $api.delete(`master/record/${recordId}`)
            .then(response => response)
    }

    static async saveSettings(data) {
        return $api.post('master/settings', data)
            .then(response => response)
    }

    static async getMasterDomains() {
        return $api.get('master/domains')
            .then(response => response)
    }

    static async saveDomainInfo(data) {
        return $api.post('master/domains', data)
            .then(response => response)
    }

    static async getMasterPwaSettings() {
        return $api.get('master/pwa')
            .then(response => response)
    }

    static async saveMasterPwaSettings(data) {
        return $api.post('master/pwa', data)
            .then(response => response)
    }

    static async getMasterClients() {
        return $api.get('master/clients')
            .then(response => response)
    }

    static async getMasterClientsList() {
        return $api.get('master/clients-list')
            .then(response => response)
    }

    static async getMasterClientInfo(uuid) {
        return $api.get(`master/client/${uuid}`)
            .then(response => response)
    }

    static async saveClientInfo(id, data) {
        return $api.put(`master/client/${id}`, data)
            .then(response => response)
    }

    static async addNewClient(data) {
        return $api.post(`master/client`, data)
            .then(response => response)
    }

    static async getRecordListByParams(params) {
        return $api.get('master/record/all', { params })
            .then(response => response)
    }

    static async getRecordData(uuid) {
        return $api.get(`master/record/${uuid}`)
            .then(response => response)
    }

    static async toggleRecordViewed(uuid) {
        return $api.put(`master/record/toggle/${uuid}`)
            .then(response => response)
    }



    static async getLedgerMonthly() {
        return $api.get('master/ledger/monthly')
            .then(response => response)
    }




    static async getClientGroups() {
        return $api.get('master/clients/groups')
            .then(response => response)
    }





    static async addClientGroup(data) {
        return $api.post('master/clients/group', data)
            .then(response => response)
    }

    static async updateClientGroup(data) {
        return $api.put('master/clients/group', data)
            .then(response => response)
    }

    static async removeClientGroup(id) {
        return $api.delete(`master/clients/group/${id}`)
            .then(response => response)
    }





    static async addSending(data) {
        return $api.post('master/sending', data)
            .then(response => response)
    }

    static async getAllSending() {
        return $api.get('master/sending', { timeout: 0 })
            .then(response => response)
    }

    static async getSendingByUuid(uuid) {
        return $api.get(`master/sending/${uuid}`)
            .then(response => response)
    }







    static async saveAddressMaster(data) {
        return $api.post('master/address', data)
            .then(response => response)
    }

    static async removeAddressMaster(uuid) {
        return $api.delete(`master/address/${uuid}`)
            .then(response => response)
    }







}
