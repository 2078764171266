<template>
  <input
      :value="phone"
      ref="fieldPhone"
      type="tel"
      @keyup="change"
      :placeholder="placeholder"
      @mouseover="setPlaceholder"
      @mouseleave="removePlaceholder"
  >
</template>

<script>
import Inputmask from "inputmask";
import parsePhoneNumber from 'libphonenumber-js'
import {isValidPhoneNumber} from 'libphonenumber-js'

export default {
  name: "component InputTelMasked",

  props: {
    modelValue: {
      type: String,
      default: ''
    },
    setFocus: {
      type: Boolean,
      default: false,
    }
  },

  PLACEHOLDER_TEXT: '+7 (___) ___-__-__',
  MASK: '+7 (999) 999-99-99',

  data() {
    return {
      phone: '',
      placeholder: this.$options.PLACEHOLDER_TEXT,
      isCompleteMaskPhone: false,
    }
  },


  watch: {
    modelValue(value) {
      if (value === '') this.phone = '';
    },
  },

  methods: {
    change(evt) {
      this.phone = evt.target.value;
      const isValid = isValidPhoneNumber(this.phone, 'RU');
      if( isValid ) {
        this.$emit("update:modelValue", `7${this.$refs.fieldPhone.inputmask.unmaskedvalue()}`);
      }
      else {
        this.$emit("update:modelValue", '');
      }
    },

    phoneFormat(number) {
      try {
        number = number + '';
        const phoneNumber = parsePhoneNumber(number, 'RU');
        number = phoneNumber.number
        return {
          phone: phoneNumber.formatNational(),
          uri: phoneNumber.getURI(),
          number: number[0] === '+' ? number = number.slice(1) : number,
        }
      }
      catch (e) {
      }

      return {
        phone: '',
        uri: '',
        number: '',
      };


    },

    setPlaceholder() {
      this.placeholder = this.$options.PLACEHOLDER_TEXT;
    },

    removePlaceholder() {
      this.placeholder = this.$options.PLACEHOLDER_TEXT;
    },

  },

  created() {
    this.phone = this.phoneFormat(this.modelValue + '').number + '';
    if (this.phone.slice(0, 1) === '+') this.phone = this.phone.slice(1);
    if (this.phone.slice(0, 1) === '7') this.phone = this.phone.slice(1);
    if (this.phone.slice(0, 1) === '8') this.phone = this.phone.slice(1);
  },

  mounted() {

    if (this.$refs.fieldPhone) {
      const im_tel = new Inputmask(this.$options.MASK);
      im_tel.mask(this.$refs.fieldPhone);
    }

    this.$nextTick(() => {
      this.$refs.fieldPhone.onfocus = () => {
        if (this.$refs.fieldPhone) {
          const im_tel = new Inputmask({
            mask: this.$options.MASK,

            onBeforePaste: (pastedValue) => {
              let arr = pastedValue.split('');
              if (arr[0] === '+') arr.splice(0, 1);
              if (arr[0] === '7') arr.splice(0, 1);
              if (arr[0] === '8') arr.splice(0, 1);
              return arr.join('');
            },

            postValidation: (buffer) => {
              if (['7', '8'].includes(buffer[4])) {
                return {
                  remove: 4,
                  caret: 4,
                }
              }
              return true;
            },

          });

          im_tel.mask(this.$refs.fieldPhone);
          if (this.setFocus) this.$refs.fieldPhone.focus();

        }
      };
    });


    this.$refs.fieldPhone.onblur = () => {
      try {
        const im_tel = this.$refs.fieldPhone;
        im_tel.inputmask.remove();
      }
      catch (e) {
      }
    };


  },

  beforeUnmount() {
    this.isCompleteMaskPhone = false;
    try {
      const im_tel = this.$refs.fieldPhone;
      im_tel.inputmask.remove();
    }
    catch (e) {
    }
  },

}
</script>
